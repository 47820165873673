<template>
  <div>
    <!-- <pre>{{ data }}</pre> -->
    <JSCharting
      :options="chartOptions"
      class="columnChart"
      v-if="!isLoading"
    ></JSCharting>
    <JSCharting
      :options="chartOptions2"
      class="columnChart"
      v-if="!isLoading"
    ></JSCharting>
    <OtherLoading v-else />
    <SideBarFilter
      id="repurchaseFilter"
      ref="repurchaseFilter"
      :filter="filter"
      :placeholder="'Search Name'"
      @clearFilter="clearFilter"
      @searchAll="filterActions"
      :hideStatusFilter="false"
      :hideSearchBar="true"
    >
      <template v-slot:filter-option>
        <InputDatePickerFilter
          textFloat="First Purchase From"
          name="first-purchase-from"
          ref="first-purchase-from"
          :value="filter.PurchaseFrom"
          :max-datetime="filter.PurchaseTo"
          @input="(val) => (filter.PurchaseFrom = val)"
        />

        <InputDatePickerFilter
          textFloat="First Purchase To"
          name="first-purchase-to"
          ref="first-purchase-to"
          :value="filter.PurchaseTo"
          @input="(val) => (filter.PurchaseTo = val)"
        />
        <div class="mb-3">
          <MultiSelect
            used="channel"
            textFloat="First Purchase Channel"
            :options="options.channelTypes"
            textField="channelName"
            valueField="id"
            placeholder="Select Channel"
            v-model="filter.PurchaseChannel"
            :value="filter.PurchaseChannel"
          />
        </div>
        <div class="mb-3">
          <MultiSelect
            textFloat="First Purchase Branch"
            :options="options.branches"
            textField="branchName"
            valueField="id"
            placeholder="Select Branch"
            v-model="filter.PurchaseBranchGroup"
            :value="filter.PurchaseBranchGroup"
          />
        </div>
        <InputSelect
          title="Min Purchase"
          name="min-purchase"
          isRequired
          :options="options.MinPurchase"
          v-model="filter.MinPurchase"
          valueField="id"
          textField="name"
        />
      </template>
    </SideBarFilter>
  </div>
</template>

<script>
import OtherLoading from "@/components/loading/OtherLoading";
import JSCharting from "jscharting-vue";
export default {
  components: {
    JSCharting,
    OtherLoading,
  },
  data() {
    return {
      chartOptions: {
        debug: false,
        type: "heatmap",
        toolbar_visible: false,
        // title_label_text: "",
        title_label_text: `Accumulated Percentage of Unique Users(Minimun 2 Purchases) for Each Cohort`,
        legend_title_label_text: "",
        // legend: { height: 800 },
        palette: {
          colors: ["#fcfed1", "#46b8c3", "#0d2162"],
          pointValue: "{%zValue}",
        },
        defaultPoint: {
          tooltip: "<b>%xValue</b> %yValue<br/>accumulate: %zValue",
          label_text: "%zValue",
          outline_width: 0,
        },
        yAxis_label_text: "Cohort (Month and Year of First Purchase)",
        xAxis_label_text: "Months Since Cohort's First Purchase",
        defaultPoint_outline_color: "none",
        series: [
          {
            name: "Call Response Time.",
            points: [
              { x: "Josh", y: "Monday", z: 1 },
              { x: "Josh", y: "Tuesday", z: 6 },
              { x: "Josh", y: "Wednesday", z: 3 },
              { x: "Josh", y: "Thursday", z: 2 },
              { x: "Josh", y: "Friday", z: 5 },
              { x: "Josh", y: "Saturday", z: 1 },
              { x: "Greg", y: "Monday", z: 4 },
              { x: "Greg", y: "Tuesday", z: 6 },
              { x: "Greg", y: "Wednesday", z: 1 },
              { x: "Greg", y: "Thursday", z: 8 },
              { x: "Greg", y: "Friday", z: 6 },
              { x: "Greg", y: "Saturday", z: 1 },
              { x: "Molly", y: "Monday", z: 2 },
              { x: "Molly", y: "Tuesday", z: 2 },
              { x: "Molly", y: "Wednesday", z: 3 },
              { x: "Molly", y: "Thursday", z: 1 },
              { x: "Molly", y: "Friday", z: 2 },
              { x: "Molly", y: "Saturday", z: 2 },
            ],
          },
        ],
      },
      chartOptions2: {
        debug: true,
        type: "area",
        title_label_text:
          "Accumulated Percentage of Unique Users Over Time by Cohort",
        legend_visible: false,
        yAxis: {
          label_text: "Accumulated Percentage (%)",
          scale: {
            /*Define an axis range of 0-100*/
            range: { min: -0.9, max: 100.9, padding: 10 },
          },
        },

        xAxis: {
          label_text: "Months Since Cohort's First Purchase",
          crosshair_enabled: true,
          // scale: { type: "time" },
        },

        defaultSeries: {
          shape_opacity: 0,
          defaultPoint_marker: {
            type: "circle",
            outline: { width: 1 },
          },
        },
        defaultPoint: {
          tooltip: "<b>%seriesName %yValue</b> ",
          marker: { size: 6, color: "darken" },
        },
        series: [
          {
            name: "Purchases",

            points: [
              ["1/1/2020", 29.9],
              ["2/1/2020", 97.5],
              ["3/1/2020", 110.4],
              ["4/1/2020", 129.2],
              ["5/1/2020", 144.0],
              ["6/1/2020", 176.0],
            ],
          },
          {
            name: "Rent",

            points: [
              ["1/1/2020", 86.9],
              ["2/1/2020", 79.5],
              ["3/1/2020", 95.4],
              ["4/1/2020", 97.2],
              ["5/1/2020", 123.0],
              ["6/1/2020", 111.0],
            ],
          },
        ],
      },
      filter: {
        PurchaseFrom: this.$moment().subtract(1, "year").format("YYYY-MM-DD"),
        PurchaseTo: this.$moment().format("YYYY-MM-DD"),
        PurchaseChannel: [],
        PurchaseBranchGroup: [],
        MinPurchase: 2,
      },
      isLoading: true,
      options: { branches: [], channelTypes: [], MinPurchase: [] },
    };
  },
  created() {
    this.getData();
    this.getFilter();
    // this.convertData();
  },
  methods: {
    async getFilter() {
      const res = await this.$report(`/RevenueReport/CustomFilter`);

      this.options = {
        ...res.data,
      };
      let arr = [
        ...Array.from({ length: 10 }, (_, i) => {
          return { id: i + 1, name: i + 1 };
        }),
      ];
      arr.splice(0, 1);
      this.options.MinPurchase = arr;
      // this.isFilterLoading = false;
    },
    async getData() {
      this.isLoading = true;
      const res = await this.$report.post(
        "/Dashboard/customerbehavior",
        this.filter
      );
      this.data = res.data.detail;
      await this.convertData();
      this.isLoading = false;
    },
    async convertData() {
      let point = [];
      var avg = [];
      let linePoint = [];
      var i = 0;
      for (const month of this.data.month) {
        // linePoint.push({
        //   name: `${month.name} (n=${month.first_purchase})`,
        //   points: [],
        //   index: 0,
        // });

        for (const value of month.value) {
          point.unshift({
            y: `${month.name} (n=${month.first_purchase})`,
            x: value.cohort,
            z: value.accumulate,
          });

          // linePoint[i].points.push([value.cohort, value.accumulate]);
        }
        i++;
      }
      linePoint.push({
        name: `Average`,
        points: [],
      });

      for (const average of this.data.average.value) {
        avg.push({
          y: `Average`,
          x: average.cohort,
          z: average.accumulate,
        });
        linePoint[0].points.push([average.cohort, average.accumulate]);
      }

      point = [...avg, ...point];
      this.chartOptions2.series = linePoint;
      this.chartOptions.series[0].points = point;
    },
    clearFilter() {
      this.filter = {
        PurchaseFrom: this.$moment().subtract(1, "year").format("YYYY-MM-DD"),
        PurchaseTo: this.$moment().format("YYYY-MM-DD"),
        PurchaseChannel: [],
        PurchaseBranchGroup: [],
        MinPurchase: 2,
      };
      this.getData();
      this.$refs.repurchaseFilter.hide();
    },
    filterActions() {
      this.chartOptions.title_label_text = `Accumulated Percentage of Unique Users(Minimun ${this.filter.MinPurchase} Purchases) for Each Cohort`;
      this.getData();
    },
    showFilter() {
      this.$refs.repurchaseFilter.show();
    },
  },
};
</script>

<style>
.columnChart {
  height: 600px;
  width: 100%;
}
</style>
